<template>
  <amplify-authenticator>
    <v-app>
      <v-app-bar app color="rgb(32, 34, 38)" dark>
        <div class="d-flex align-center"></div>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>
          <span>Data Explorer</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-app-bar>
      <v-navigation-drawer app v-model="drawer" color="rgb(32, 34, 38)" dark>
        <v-list>
          <v-list-item
            v-for="link in links"
            :key="link.text"
            route
            :to="link.route"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title @click="currentPage = link.text" dark>{{
                link.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <amplify-sign-out></amplify-sign-out>
      </v-navigation-drawer>

      <v-main>
        <div>
          <router-view></router-view>
        </div>
      </v-main>
    </v-app>
  </amplify-authenticator>
</template>

<script>
import { mapActions } from "vuex";
import { CogniteClient } from "@cognite/sdk6";

export default {
  name: "App",
  data() {
    return {
      drawer: false,
      links: [
        {
          text: "Explorer",
          route: "/explorer",
          icon: "mdi-cloud-search",
        },
        {
          text: "3D Viewer",
          route: "/3d",
          icon: "mdi-video-3d",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["setCDFClient"]),
  },
  created() {
    // Create a CDF client and put it on Vuex store.
    const project = "toyo-dev";
    // Please ask the author, Kosuke Uchihashi for the API key.
    const apiKey = "ZGY5YjQzZTItMmY1My00MGVlLWI3YjUtY2FhZjBlNzI2YjYz";
    const client = new CogniteClient({
      appId: "cognite vue test",
      project,
      apiKeyMode: true,
      getToken: () => Promise.resolve(apiKey),
    });
    client.authenticate().then(() => {
      this.setCDFClient(client);
    });
  },
};
</script>
