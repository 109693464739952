import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectedTimeseriesName: "",
    CDFClient: null,
  },
  getters: {
    selectedTimeseriesName: state => state.selectedTimeseriesName,
    CDFClient: state => state.CDFClient
  },
  mutations: {
    setCDFClient(state, client) {
      state.CDFClient = client;
    },
    setSelectedTimeseriesName(state, name) {
      state.selectedTimeseriesName = name;
    },
  },
  actions: {
    setCDFClient({ commit }, client) {
      commit("setCDFClient", client)
    },
    setSelectedTimeseriesName({ commit }, name) {
      commit("setSelectedTimeseriesName", name)
    },
    }
});
