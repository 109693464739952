import Vue from "vue";
import App from "./App.vue";
import vuetify from './plugins/vuetify'
import router from "./router";
import VueApexCharts from 'vue-apexcharts';
import store from './store';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Amplify.configure(aws_exports);
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "AWS",
        endpoint: "https://84kvb106n8.execute-api.ap-northeast-1.amazonaws.com/api/",
        region: "ap-northeast-1"
      }
    ]
  }
})
applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];

Vue.config.productionTip = false;


new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");

Vue.component('apexchart', VueApexCharts)