import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/explorer',
    name: 'DataExplorer',
    component: () => import(/* webpackChunkName: "dataExplorer" */ '../views/DataExplorer.vue'),
    children: [
      {
        path: ':id',
        component: () => import(/* webpackChunkName: "resourceSearch" */ '../components/DashboardAsset.vue')
      }
    ],
  },
  {
    path: '/3d',
    name: '3DModelViewer',
    component: () => import(/* webpackChunkName: "3DModelViewer" */ '../views/3DModelViewer.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
